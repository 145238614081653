@import 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; scroll-behavior: smooth; color: var(--text-dark); }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.text-light {
  color: var(--text-light);
}

.text-light-muted {
  color: var(--text-light-muted);
}

.text-dark {
  color: var(--text-dark);
}

.text-dark-muted {
  color: var(--text-dark-muted);
}

.button-gradient-primary {
  background: var(--button-gradient-primary);
  color: var(--text-light);
}

.button-gradient-accent {
  background: var(--button-gradient-accent);
  color: var(--text-light);
}

.icon-accent {
  @apply bg-clip-text text-transparent;
  background-image: var(--icon-gradient-accent);
}
