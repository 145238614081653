:root {
  color-scheme: light;

  --text-dark: rgba(48,48,48);
  --text-dark-muted: rgba(48,48,48, 0.7);

  --text-light: rgba(255,255,255);
  --text-light-muted: rgba(255,255,255, 0.75);

  --brand-primary: #7B1FA2;
  --brand-primary-light: #9C27B0;
  --brand-accent: #7C4DFF;
  --brand-accent-light: #b094ff;

  --color-danger: #C62828;
  --color-success: #388E3C;

  --button-gradient-primary: linear-gradient(to right, var(--brand-primary), #954cb5);
  --button-gradient-accent: linear-gradient(to right, #633ecc, var(--brand-accent));
  --icon-gradient-accent: linear-gradient(135deg, var(--brand-accent), var(--brand-accent-light));

  --gradient-danger: linear-gradient(to right, #9e2020, var(--color-danger), #9e2020);
  --gradient-success: linear-gradient(to right, #2d7230, var(--color-success), #2d7230);
  --gradient-primary: linear-gradient(to right, var(--brand-primary), #954cb5, var(--brand-primary));
  --gradient-accent: linear-gradient(to right, #633ecc, var(--brand-accent), #633ecc);

  --app-background: #ffffff;

  --border-radius: 4px;
}
